import React from 'react'
import MediaQuery from "react-responsive";
import exhibition01 from '../assets/images/exhibition/yosomono/01.jpg'
import exhibition02 from '../assets/images/exhibition/yosomono/02.jpg'
import exhibition03 from '../assets/images/exhibition/yosomono/03.jpg'
import exhibition04 from '../assets/images/exhibition/yosomono/04.jpg'
import exhibition05 from '../assets/images/exhibition/yosomono/05.jpg'
import exhibition06 from '../assets/images/exhibition/yosomono/06.jpg'
import exhibition07 from '../assets/images/exhibition/yosomono/07.jpg'
import exhibition08 from '../assets/images/exhibition/yosomono/08.jpg'
import exhibition09 from '../assets/images/exhibition/yosomono/09.jpg'
import exhibition10 from '../assets/images/exhibition/yosomono/10.jpg'
import exhibition11 from '../assets/images/exhibition/yosomono/11.jpg'
import exhibition12 from '../assets/images/exhibition/yosomono/12.jpg'
import exhibition13 from '../assets/images/exhibition/yosomono/13.jpg'
import thumb01 from '../assets/images/exhibition/yosomono/01.jpg'
import thumb02 from '../assets/images/exhibition/yosomono/02.jpg'
import thumb03 from '../assets/images/exhibition/yosomono/03.jpg'
import thumb04 from '../assets/images/exhibition/yosomono/04.jpg'
import thumb05 from '../assets/images/exhibition/yosomono/05.jpg'
import thumb06 from '../assets/images/exhibition/yosomono/06.jpg'
import thumb07 from '../assets/images/exhibition/yosomono/07.jpg'
import thumb08 from '../assets/images/exhibition/yosomono/08.jpg'
import thumb09 from '../assets/images/exhibition/yosomono/09.jpg'
import thumb10 from '../assets/images/exhibition/yosomono/10.jpg'
import thumb11 from '../assets/images/exhibition/yosomono/11.jpg'
import thumb12 from '../assets/images/exhibition/yosomono/12.jpg'
import thumb13 from '../assets/images/exhibition/yosomono/13.jpg'
import pcthumb01 from '../assets/images/exhibition/yosomono/thumbnail/01.jpg'
import pcthumb02 from '../assets/images/exhibition/yosomono/thumbnail/02.jpg'
import pcthumb03 from '../assets/images/exhibition/yosomono/thumbnail/03.jpg'
import pcthumb04 from '../assets/images/exhibition/yosomono/thumbnail/04.jpg'
import pcthumb05 from '../assets/images/exhibition/yosomono/thumbnail/05.jpg'
import pcthumb06 from '../assets/images/exhibition/yosomono/thumbnail/06.jpg'
import pcthumb07 from '../assets/images/exhibition/yosomono/thumbnail/07.jpg'
import pcthumb08 from '../assets/images/exhibition/yosomono/thumbnail/08.jpg'
import pcthumb09 from '../assets/images/exhibition/yosomono/thumbnail/09.jpg'
import pcthumb10 from '../assets/images/exhibition/yosomono/thumbnail/10.jpg'
import pcthumb11 from '../assets/images/exhibition/yosomono/thumbnail/11.jpg'
import pcthumb12 from '../assets/images/exhibition/yosomono/thumbnail/12.jpg'
import pcthumb13 from '../assets/images/exhibition/yosomono/thumbnail/13.jpg'
import Gallery from '../components/Gallery'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import Exhibitionnav from '../components/Exhibitionnav'

const DEFAULT_IMAGES = [
  {
    id: '1',
    source: exhibition01,
    thumbnail: pcthumb01,
    caption: 'Photo 1',
    description: '',
  },
  {
    id: '2',
    source: exhibition02,
    thumbnail: pcthumb02,
    caption: 'Photo 2',
    description: '',
  },
  {
    id: '3',
    source: exhibition03,
    thumbnail: pcthumb03,
    caption: 'Photo 3',
    description: '',
  },
  {
    id: '4',
    source: exhibition04,
    thumbnail: pcthumb04,
    caption: 'Photo 4',
    description: '',
  },
  {
    id: '5',
    source: exhibition05,
    thumbnail: pcthumb05,
    caption: 'Photo 5',
    description: '',
  },
  {
    id: '6',
    source: exhibition06,
    thumbnail: pcthumb06,
    caption: 'Photo 6',
    description: '',
  },
  {
    id: '7',
    source: exhibition07,
    thumbnail: pcthumb07,
    caption: 'Photo 7',
    description: '',
  },
  {
    id: '8',
    source: exhibition08,
    thumbnail: pcthumb08,
    caption: 'Photo 1',
    description: '',
  },
  {
    id: '9',
    source: exhibition09,
    thumbnail: pcthumb09,
    caption: 'Photo 2',
    description: '',
  },
  {
    id: '10',
    source: exhibition10,
    thumbnail: pcthumb10,
    caption: 'Photo 3',
    description: '',
  },
  {
    id: '11',
    source: exhibition11,
    thumbnail: pcthumb11,
    caption: 'Photo 11',
    description: '',
  },
  {
    id: '12',
    source: exhibition12,
    thumbnail: pcthumb12,
    caption: 'Photo 4',
    description: '',
  },
  {
    id: '13',
    source: exhibition13,
    thumbnail: pcthumb13,
    caption: 'Photo 5',
    description: '',
  },
]
const DEFAULT_IMAGES2 = [
  {
    id: '1',
    source: exhibition01,
    thumbnail: thumb01,
    caption: 'Photo 1',
    description: '',
  },
  {
    id: '2',
    source: exhibition02,
    thumbnail: thumb02,
    caption: 'Photo 2',
    description: '',
  },
  {
    id: '3',
    source: exhibition03,
    thumbnail: thumb03,
    caption: 'Photo 3',
    description: '',
  },
  {
    id: '4',
    source: exhibition04,
    thumbnail: thumb04,
    caption: 'Photo 4',
    description: '',
  },
  {
    id: '5',
    source: exhibition05,
    thumbnail: thumb05,
    caption: 'Photo 5',
    description: '',
  },
  {
    id: '6',
    source: exhibition06,
    thumbnail: thumb06,
    caption: 'Photo 6',
    description: '',
  },
  {
    id: '7',
    source: exhibition07,
    thumbnail: thumb07,
    caption: 'Photo 7',
    description: '',
  },
  {
    id: '8',
    source: exhibition08,
    thumbnail: thumb08,
    caption: 'Photo 1',
    description: '',
  },
  {
    id: '9',
    source: exhibition09,
    thumbnail: thumb09,
    caption: 'Photo 2',
    description: '',
  },
  {
    id: '10',
    source: exhibition10,
    thumbnail: thumb10,
    caption: 'Photo 3',
    description: '',
  },
  {
    id: '11',
    source: exhibition11,
    thumbnail: thumb11,
    caption: 'Photo 11',
    description: '',
  },
  {
    id: '12',
    source: exhibition12,
    thumbnail: thumb12,
    caption: 'Photo 4',
    description: '',
  },
  {
    id: '13',
    source: exhibition13,
    thumbnail: thumb13,
    caption: 'Photo 5',
    description: '',
  },
]

const siteTitle = 'Onodera Ryo | exhibition'

const Exhibition05 = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      nonClass: '',
    }
  }
  toggleNon = () => {
    console.log("111");
    this.setState({
      nonClass: 'is-active',
    })
  }

  componentDidMount() {
    setTimeout(() => {
      this.toggleNon();
    },700);
  }

  render() {
    return (
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
      </Helmet>
      <div id="main">
      <div className="portrait">
        <Exhibitionnav>
          "YOSOMONO" (2016)　Apr 30th - Mar 1th　<br></br>Cast：Kaito Yoshimura　At 233 Gallery Setagaya Tokyo
        </Exhibitionnav>
        <div class={`gall ${this.state.nonClass}`}>
        <MediaQuery query="(min-width: 767px)">
          <Gallery
                  images={DEFAULT_IMAGES.map(
                    ({ id, source, thumbnail, caption, description }) => ({
                      source,
                      thumbnail,
                      caption,
                      description,
                    })
                  )}
          />
        </MediaQuery>
        <MediaQuery query="(max-width: 767px)">
          <Gallery
                  images={DEFAULT_IMAGES2.map(
                    ({ id, source, thumbnail, caption, description }) => ({
                      source,
                      thumbnail,
                      caption,
                      description,
                    })
                  )}
          />
        </MediaQuery>
        </div>
      </div>
      </div>
    </Layout>
  )
  }
}

export default Exhibition05